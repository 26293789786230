import * as React from "react";
import { useState } from "react";
import unsplash from "../api/unsplash";
import SearchBar from "./SearchBar";
import ImageList from "./ImageList";

const App = () => {
  // Initialize with an empty array as the state will be a list
  const [state, setState] = useState({ images: [] });

  const onSearchSubmit = async (term) => {
    const response = await unsplash.get("/search/photos", {
      params: { query: term },
    });

    setState({ images: response.data.results });
  };
  return (
    <div className="ui container" style={{ marginTop: "10px " }}>
      <SearchBar onSubmit={onSearchSubmit} />
      <ImageList images={state.images} />
    </div>
  );
};

export default App;
