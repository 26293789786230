import * as React from "react";
import { useState, useEffect, useRef } from "react";

const ImageCard = ({ image }) => {
  const [state, setState] = useState({ spans: 0 });
  const imageRef = useRef(null);

  const setSpans = () => {
    const height = imageRef.current.clientHeight;

    const spans = Math.ceil(height / 10);

    setState({ spans });
  };

  useEffect(() => {
    imageRef.current.addEventListener("load", setSpans);
  });

  const { alt_description, urls } = image;
  return (
    <div style={{ gridRowEnd: `span ${state.spans}` }}>
      <img ref={imageRef} alt={alt_description} src={urls.regular} />
    </div>
  );
};

export default ImageCard;
