import * as React from "react";
import { useState } from "react";

const SearchBar = (props) => {
  const [state, setState] = useState({ term: "" });

  const onFormSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(state.term);
  };

  return (
    <div className="ui segment">
      <form className="ui form" onSubmit={onFormSubmit}>
        <div className="field">
          <label>Image search</label>
          <input
            type="text"
            value={state.term}
            onChange={(e) => setState({ term: e.target.value })}
            placeholder="Input search term and hit enter"
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
